import {
  type Blot,
  CalcOptions,
  Commands,
  type EditorOptions,
  generateStylesSystem,
  Numbered,
  Query,
  Registry,
  ScheduledBlot,
  ScheduleType,
  Scroll,
  SystemOptions,
  SystemTime,
  updateNumberingClassSystem,
  updateNumberingPadding,
  updateNumberingSystem,
  updateStylesSystem
} from '@avvoka/editor'
import type {CustomClauseVariantStoreType} from '@stores/generic/customClauseVariant.store'
import {type TemplateVersionStoreType} from '@stores/generic/templateVersion.store'
import {StoreMode} from '@stores/utils'
import {type Ref, watch} from 'vue'
import useDebounce from "~/features/_abstract/utils/debounce";
import {ReturnContent, transformEditorStyles} from "~/features/editor/styles/transform";
import {
  createBlotMockup,
  createBlotsMockupFromElements,
  createGlobalStyles,
  generateLineHeights,
  getEditorOptionsMockup,
  getMeasurementNode,
  getStagesMockup
} from "~/features/editor/styles/utils";
import {type DocumentStoreType} from '@stores/generic/document.store'
import {useLocalize} from "~/library/utils/localization";
import {useErrorToast} from "~/library/utils/toasts";
import morphdom from 'morphdom';

async function yieldToEventLoop() {
  await new Promise((resolve) => setTimeout(resolve, 0))
}

/**
 * Renders editor styles by creating mockup blots and applying styles to the DOM.
 * This function should only be used in Agreement mode where the editor is not available.
 *
 * @param {StoreWithStyles} store - The store containing style definitions
 * @param {HTMLElement} [contentElement] - Optional content element for agreement mode
 *
 * @description
 * This function:
 * 1. Creates mockup blots to simulate editor behavior
 * 2. Generates and applies styles to the DOM
 * 3. Only runs its full logic in Agreement mode (when editor is absent)
 *
 * Note: In Editor mode, only global styles are created as the editor
 * handles the rest of the styling system.
 *
 * @internal This function is called by handleEditorStyles and shouldn't be used directly
 */
const createEditorStyles = async (
  store: StoreWithStyles,
  contentElement: Element,
  numbereds: Numbered[],
  editorOptions: EditorOptions
) => {
  const flatten = (arr: Blot[], result: Blot[] = []): Blot[] => {
    arr.forEach((blot) => {
      result.push(blot)
      if ('children' in blot) {
        flatten(blot.children as Blot[], result)
      }
    })
    return result
  }
  const { PX_TO_MM } = getMeasurementNode()
  const blotsMockup = flatten(
    createBlotsMockupFromElements(
      editorOptions,
      Array.from(contentElement.querySelectorAll('.avv-container > *'))
    )
  )
  const commands = new Commands()

  const scrollMockup = new Scroll(
    contentElement.querySelector('.avv-container') as HTMLElement,
    new Registry(),
    editorOptions,
    getStagesMockup()
  )
  scrollMockup.children = blotsMockup


  // Recalculate numberings for indentantion
  const numbering = updateNumberingSystem()
  numbering[3](
    numbereds,
    new SystemTime(1),
    new SystemOptions(editorOptions)
  )

  await yieldToEventLoop()

  // Rebuild blots mockup, since the numbering system might have changed the blots
  const blotsMockupUpdated = flatten(
    createBlotsMockupFromElements(editorOptions, Array.from(contentElement.querySelectorAll('.avv-container > *')))
  )
  scrollMockup.children = blotsMockupUpdated

  await yieldToEventLoop()

  const numberingClass = updateNumberingClassSystem()
  numberingClass[3](numbereds, new SystemTime(1))

  await yieldToEventLoop()

  const numberingPadding = updateNumberingPadding()
  numberingPadding[3](
    numbereds,
    new ScheduledBlot(numbereds),
    new SystemTime(1),
    commands,
    scrollMockup,
    new SystemOptions(editorOptions),
    new CalcOptions(PX_TO_MM, () => {
      throw new Error('Not implemented')
    })
  )

  await yieldToEventLoop()

  // Recalculate all styles
  const updateStyles = updateStylesSystem()
  updateStyles[3](
    new ScheduledBlot(scrollMockup.children),
    commands,
    new SystemOptions(editorOptions)
  )

  await yieldToEventLoop()

  // We need access to the global styles
  if (store.hydratedData || store.storeMode == StoreMode.NewData) {
    // Generate line-heights
    generateLineHeights(store, commands, scrollMockup)
  }

  await yieldToEventLoop()

  // Generate styles
  generateStylesSystem()[3](commands, {
    type: ScheduleType.ENTITIES,
    data: commands.spawnBuffer
  })
}

/**
 * Sets up the style management system for either editor or agreement mode.
 * This function establishes style watchers and manages the initial style rendering
 * based on the current context.
 *
 * @param {StoreWithStyles} store - The store containing style definitions and settings
 * @param {HTMLElement} [contentElement] - Optional content element for agreement mode
 *
 * @description
 * This function handles two distinct modes with different requirements:
 *
 * 1. Editor Mode:
 *    - Style transformation is handled automatically through editor hooks
 *    - renderEditorStyles is not needed as the editor handles style updates
 *    - Store changes trigger automatic style updates through editor hooks
 *
 * 2. Agreement Mode:
 *    - Requires manual style transformation when store values change
 *    - renderEditorStyles must run to create mockup blots (editor not available)
 *    - Developers must call transformEditorStyles manually when store updates
 *
 * Important Usage Notes:
 * - In Agreement mode, when store values change, you must manually call:
 *   ```typescript
 *   transformEditorStyles(htmlContent, store.docxSettings.formats, 'add', ReturnContent.Html, 'document')
 *   ```
 * - The watcher established by this function only handles the visual representation
 *   of styles, not the underlying HTML structure
 *
 * @example
 * // Editor Mode (styles are handled automatically)
 * renderEditorStyles(editorStore);
 *
 * @example
 * // Agreement Mode (requires manual transformation)
 * renderEditorStyles(agreementStore, agreementElement);
 *
 * // When store changes in Agreement Mode, you must:
 * watch(() => agreementStore.docxSettings, async () => {
 *   const newHtml = transformEditorStyles(
 *     currentHtml,
 *     agreementStore.docxSettings.formats,
 *     'add',
 *     ReturnContent.Html,
 *     'document'
 *   );
 *   // Update your HTML content
 *   element.innerHTML = newHtml;
 * });
 *
 * @see {@link renderEditorStyles} For the actual rendering implementation
 * @see {@link transformEditorStyles} For the style transformation logic
 */
export const renderEditorStyles = async (
  store: StoreWithStyles,
  contentElement?: Element
) => {
  let numberings: Numbered[];

  const editorOptions = await getEditorOptionsMockup()

  if (contentElement) {
    const numberingElements = Array.from(
      contentElement.querySelectorAll('avv-numbered')
    )
    const numberingBlots = numberingElements.map((el) => createBlotMockup(el as HTMLElement, editorOptions))
    // Filter out null values and cast to Numbered type
    numberings = numberingBlots.filter((blot): blot is Numbered =>
      blot !== null
    )
  } else {
    numberings = EditorFactory.mainOptional.mapOr(
      (editor) => editor.query(Query('numbered')) as Numbered[],
      undefined
    ) ?? []
  }

  if (EditorFactory.mainOptional.isAbsent() || contentElement) {
    await createEditorStyles(
      store,
      contentElement ?? EditorFactory.main.scroll.node,
      numberings,
      editorOptions
    )
  }

  await createGlobalStyles(store, numberings, editorOptions)
}

export type StoreWithStyles =
  | DocumentStoreType
  | TemplateVersionStoreType
  | CustomClauseVariantStoreType

/**
 * Synchronizes both content transformation and visual styling for agreement documents.
 * This function handles the complete style synchronization process by:
 * 1. Transforming the HTML content with updated styles
 * 2. Applying the visual styles to the DOM
 *
 * @param store - The store containing style definitions and settings
 * @param element - The root element of the agreement document
 * @param html - The current HTML content to be transformed
 * @returns The transformed HTML content with updated styles
 *
 * @description
 * This function serves as a high-level API for agreement style management,
 * ensuring both the content structure and visual styles stay synchronized
 * with the store's style definitions.
 *
 * Key Operations:
 * - Transforms HTML content using current style definitions
 * - Updates the DOM with transformed content
 * - Sets up visual style rendering
 * - Establishes store watchers for future updates
 * - Sets up interaction listeners to optimize rendering timing
 *
 * Common Usage Scenarios:
 * 1. Initial agreement rendering
 * 2. Store style updates
 * 3. Content changes requiring style reapplication
 *
 * @example
 * // Initial setup
 * const html = syncAgreementStyle(store, rootElement, initialHtml);
 *
 * // Handle store updates
 * watch(() => store.docxSettings, () => {
 *   const newHtml = syncAgreementStyle(store, rootElement, currentHtml);
 *   // Store the new HTML if needed
 *   currentHtml = newHtml;
 * });
 *
 * @throws {Error} If the provided HTML is invalid or cannot be transformed
 *
 * @see {@link transformEditorStyles} For the underlying content transformation
 * @see {@link renderEditorStyles} For the visual style rendering
 */
export function syncAgreementStyle(
  store: StoreWithStyles,
  element: Ref<Element | null>,
  html: Ref<string>,
) {
  const localize = useLocalize()
  const debounced = useDebounce(() => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        if(!element.value) return;
        element.value.parentElement?.classList.add('loading')
        requestAnimationFrame(async () => {
          if(!element.value) return;
          try {
            const newHtml = `<div class="${element.value.classList.toString()}">${transformEditorStyles(
              html.value,
              store.docxSettings.formats,
              'add',
              ReturnContent.Html,
              'document'
            )}</div>`

            // morphdom is defacto element.value.innerHTML = newHtml // but with diff-patching (more efficient)
            morphdom(element.value, newHtml);

            await renderEditorStyles(store, element.value);
          } catch (e) {
            useErrorToast(localize('editor.app.styles.error_sync'))
            console.error('Failed to render editor styles', e)
          } finally {
            element.value?.parentElement?.classList.remove('loading')
          }
        })
      })
    }, 0)
  }, 1000, false)

  // Set up interaction listeners to optimize rendering timing
  setupInteractionListeners(debounced);

  // Watch for changes and update styles accordingly
  watch([html, store.docxSettings, element], debounced, {deep: true, immediate: true})
}

/**
 * Sets up document-level event listeners to optimize style rendering timing.
 *
 * @param debouncedFn - The debounced function to execute when interactions occur
 */
function setupInteractionListeners(debouncedFn: { (): void, isScheduled: boolean }) {
  // Execute pending updates on direct user interactions
  document.addEventListener('pointerdown', () => {
    if(debouncedFn.isScheduled) {
      debouncedFn();
    }
  })

  document.addEventListener('keydown', () => {
    if(debouncedFn.isScheduled) {
      debouncedFn();
    }
  })

  // Track mouse movement and execute if significant movement detected
  let mouseDelta = 0;
  document.addEventListener('mousemove', (e) => {
    if(debouncedFn.isScheduled) {
      mouseDelta += Math.abs(e.movementX) + Math.abs(e.movementY);
      if(mouseDelta > 20) {
        debouncedFn();
        mouseDelta = 0;
      }
    }
  })
}
